//Todo move other app access type to enums
export enum AppAccessEnum {
  ac_AccessManagement = 'ac_AccessManagement',
  lm_Location_Managment = 'lm_Location_Managment',

  ContractorUsers_Contractor_Read = 'ContractorUsers_Contractor_Read',
  ContractorUsers_Contractor_Manage = 'ContractorUsers_Contractor_Manage',
  contractorTransfer = 'ContractorUsers_Contractor_Transfer',
  SystemSettings_News_Manage = 'SystemSettings_News_Manage',
  SystemSettings_DropDowns_Manage = 'SystemSettings_DropDowns_Manage',
  sts_General_View = 'sts_General_View',
  sts_PermissionRequests_View = 'sts_PermissionRequests_View',
  sts_SecurityReports_View = 'sts_SecurityReports_View',
  sts_SecurityNotes_View = 'sts_SecurityNotes_View',
  SystemSettings_Feedbacks_Manage = 'SystemSettings_Feedbacks_Manage',
  SystemSettings_Feedbacks_Add = 'SystemSettings_Feedbacks_Add',
  DelegationList = 'ac_Delegation_List',
  ac_Delegation_Managment = 'ac_Delegation_Managment',
  sn_SecurityNote_Manage_Types_And_Titles = 'sn_SecurityNote_Manage_Types_And_Titles',
  sp_SearchBySerialNumber = 'sp_SearchBySerialNumber',
  SecurityMenMyTasksManagement = 'wa_ArmedSecurityMan',
  WeaponsModule = 'wa_',
}

export enum ShiftAccessEnum {
  WriteGroup = 'sm_Group_Write',
  ReadGroup = 'sm_Group_Read',
  ReadSchedule = 'sm_Schedule_Read',
  AddSchedule = 'sm_Schedule_Add',
  EditSchedule = 'sm_Schedule_Edit',
  DeleteSchedule = 'sm_Schedule_Delete',
}

export enum SecurityNoteAccessEnum {
  sn_SecurityNote_Post = 'sn_SecurityNote_Post',
  sn_SecurityNote_List = 'sn_SecurityNote_List',
  sn_SecurityNote_Statistics = 'sn_SecurityNote_Statistics',
  sn_SecurityNote_Delete = 'sn_SecurityNote_Delete',
  sn_SecurityNote_Put = 'sn_SecurityNote_Put',
}

export enum WeaponsFormsManagementAccessEnum {
  Create = 'wa_WeaponForms_Create',
  Update = 'wa_WeaponForms_Update',
  Delete = 'wa_WeaponForms_Delete',
  List = 'wa_WeaponForms_List',
}

export enum MenArmingRequestsAccessEnum {
  Create = 'wa_SecurityManArmingRequests_Create',
  List = 'wa_SecurityManArmingRequests_List',
  Action = 'wa_SecurityManArmingRequests_TakeAction',
}

export enum SafesAccessEnum {
  Management = 'wa_Safes_Management',
  Create = 'wa_Safes_Create',
}

export const ServicesAndRequestsAccess = {
  ssr_MaintenanceRequest_Post: 'ssr_MaintenanceRequest_Post',
  ssr_MaintenanceRequest_View: 'ssr_MaintenanceRequest_View',
  ssr_MaintenanceRequest_TakeDecision: 'ssr_MaintenanceRequest_TakeDecision',

  ssr_MaintenanceDepartment_Post: 'ssr_MaintenanceDepartment_Post',

  ssr_NewLocationRequest_Post: 'ssr_NewLocationRequest_Post',
  ssr_NewLocationRequest_View: 'ssr_NewLocationRequest_View',
  ssr_NewLocationRequest_TakeDecision: 'ssr_NewLocationRequest_TakeDecision',

  si_Incident_View: 'si_Incident_View',
  si_Incident_Post: 'si_Incident_Post',
  si_OperationRoom_Management: 'si_OperationRoom_Management',
  incidentSelectEmployees: 'si_Incident_Assign_Initiators',
  incidentInitiationReport: 'si_Post_Initiation_Report',
  incidentReopen: 'si_Incident_Reopen',
  incidentDetails: 'si_Incident_View_Details',
};

export enum WeaponsGateArmingRequestAccessEnum {
  Create = 'wa_GateArmingRequests_Create',
  List = 'wa_GateArmingRequests_List',
  TakeAction = 'wa_GateArmingRequests_TakeAction',
}

export enum SystemAdminAccessEnum {
  ac_SystemAdminManagement_Add = 'ac_SystemAdminManagement_Add',
  ac_SystemAdminManagement_List = 'ac_SystemAdminManagement_List',
  ac_SystemAdminManagement_Delete = 'ac_SystemAdminManagement_Delete',
}
export enum PermissionsManagementAccessEnum {
  ReadJobTitles = 'ac_JobTitles',
  AddTemporaryEntry = 'sp_TemporaryEntry_Post',
  AddVisitorEntry = 'sp_VisitorEntry_Post',
  AddRenewTemporaryEntry = 'sp_RenewTemporaryEntry_Post',
  AddPhotoCaptureForArea = 'sp_PhotoCaptureForArea_Post',
  AddPhotoCapture = 'sp_PhotoCapture_Post',
  AddDeviceWithCamera = 'sp_DeviceWithCamera_Post',
  AddOneTimeInputOutput = 'sp_OneTimeInputOutput_Post',
  AddMultipleInputOutput = 'sp_MultipleInputOutput_Post',
  AddReviewOneTimeInputOutput = 'sp_ReviewOneTimeInputOutput_Post',
  Statistics = 'sp_Statistics',
}
export enum SecurityReportsAccessEnum {
  list = 'sr_ReportsList',
  viewProjectsFollow = 'sr_SecurityProjects_View',
  addProjectsFollow = 'sr_SecurityProjects_Post',
  editProjectsFollow = 'sr_SecurityProjects_Put_Status',
  addEntryFuelTruck = 'sr_FuelTrucksReport_Post_Entry',
  addExitFuelTruck = 'sr_FuelTrucksReport_Post_Exit',
  viewFuelTruck = 'sr_FuelTrucksReport_View',
  handOverList = 'sr_SecurityHandoverReports_Manage',
  addHandOver = 'sr_SecurityHandoverReports_Post',
  handOverDeclaration = 'sr_SecurityHandoverReports_AddEditCustody',
  peopleAndVehicleEntry = 'sr_EntryExitReports_Post_Entry',
  peopleAndVehicleExit = 'sr_EntryExitReports_Post_Exit',
  peopleAndVehicleList = 'sr_EntryExitReports_View',
  addLocationData = 'sr_LocationSecurityData_Post',
  addDailyReport = 'sr_DailySecurityReports_Post',
  addScanReport = 'sr_SecurityScanReports_Post',
  addLightingReport = 'sr_LightingSecurityFencesReports_Post',
  addInspectionReport = 'sr_SecurityManagersInspectionVisit_Post',
}

export enum UserGroupsAccessEnum {
  Management = 'ac_UserGroups_Managment',
  List = 'ac_UserGroups_List',
}

export enum WeaponWarehousesAccessEnum {
  Write = 'wa_WeaponWarehouses_Write',
  Read = 'wa_WeaponWarehouses_Read',
}

export enum SecurityMenManagementAccessEnum {
  Management = 'wa_SecurityMenManagement_Management',
}

export enum SecurityMenTasksAccessEnum {
  Management = 'wa_SecurityMenTasks_Management',
}

export enum WeaponSettingsAccessEnum {
  Management = 'wa_Settings_Management',
}

export enum WeaponsSafesHandoverAccessEnum {
  Management = 'wa_Safes_Handover',
}
